/* .trestle-drawer .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border: none !important;
  min-width: 315px !important;
  width: 315px !important;
}

.trestle-drawer-fit .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border: none !important;
  width: 90px !important;
}

.trestle-drawer-fit .css-109nrww {
  border: none !important;
  width: 90px !important;
}

.trestle-drawer-fit .MuiDrawer-paper{
  border: none !important;
  width: 90px !important;
}

.MuiDrawer-paper{
  border: none !important;
}

.trestle-drawer .css-109nrww {
  border: none !important;
  width: 90px !important;
}

.css-1mq14yt {
  border: none !important;
  min-width: 315px !important;
  width: 315px !important;
}

.trestle-drawer .css-1l8j5k8 {
  border: none !important;
} */

/* .loader-dialog .css-10d30g3-MuiPaper-root-MuiDialog-paper {
  background: transparent !important;
  overflow: hidden !important;
  box-shadow: none !important;
  color: #006d88 !important;
} */

/* .loader-dialog .css-uhb5lp {
  background: transparent !important;
  overflow: hidden !important;
  box-shadow: none !important;
  color: #006d88 !important;
} */

.Mui-selected {
  background-color: #1a4753 !important;
  color: #f3f3f3 !important;
}
