.brandingPatternBg {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  /* background-image: url(../../assets/TrestleBgPattern.svg); */
  background: linear-gradient(
    157.73deg,
    rgba(162, 240, 236, 0) 31.39%,
    #e5f4f7 60.56%
  );
  background-size: cover;
  padding: 16px 16px;
}

@media (max-width: 640px) {
  .brandingPatternBg {
    min-height: fit-content;
  }
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.rdrMonthsVertical {
  flex-direction: row !important;
}

.css-vsiucd-MuiButtonBase-root-MuiAccordionSummary-root.Mui-focusVisible {
  background-color: transparent !important;
}

.menu-hover.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #1d4e5ab2 !important;
  color: white !important;
}

.dialogInvitation .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 700px !important;
  width: 100% !important;
}

.otp-input {
  width: 80px !important;
  height: 80px !important;
}

@media (max-width: 1280px) {
  .otp-input {
    width: 32px !important;
    height: 32px !important;
  }
}

.brandingPageText {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  width: 476px;
  color: #00485a;
}

@media (max-width: 1250px) {
  .brandingPageText {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    width: fit-content;
  }
}

.testRequestContainer {
  display: flex;
  align-items: center;
  padding: 11px 30px 11px 30px;
  gap: 10px;
  width: 100%;
  height: 40px;
  background: #1635450d;
}

.brandingPageSubText {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #00485a;
}

@media (max-width: 1250px) {
  .brandingPageSubText {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
  }
}

.listItemText {
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: -0.01em;
  text-align: center;
  width: 100%;
  text-wrap: wrap;
}
