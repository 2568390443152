.select-dropdown .css-13cymwt-control{
    background: #F3F5F6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.css-13cymwt-control:focus{
    background: #F3F5F6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.css-13cymwt-control:hover {
    background: #F3F5F6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}
.css-t3ipsp-control {
    background: #F3F5F6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.css-t3ipsp-control:hover {
    background: #F3F5F6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.css-1nmdiq5-menu{
    position: absolute !important;
    z-index: 999999 !important;
}